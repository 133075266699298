"use strict";

// require("gsap/dist/gsap.min.js");

const { divide, toLength, remove, drop } = require("lodash");
require("owl.carousel/dist/owl.carousel.min.js");
// import Litepicker from "litepicker";

// const radioRound = document.querySelector(".booking_check-round");
// const bookingCheck = document.querySelectorAll(".booking_check");
// const bookingRow = document.querySelector(".booking_form-row-depart");
// const bookingTabsContainer = document.querySelector(".booking_tabs-container");
// const bookingFormCols = document.querySelectorAll(".booking_form-fields-col");
const oneWayInternationalRadio = document.querySelector("#oneWayInternational");
const oneWayVehicleRadio = document.querySelector("#oneWayVehicle");
const oneWayDomesticRadio = document.querySelector("#oneWay");
const bookingInputs = document.querySelectorAll(".booking_form-fields-col input");
const showOptionsHidden = document.querySelectorAll(".show_options-hidden");

const showOptions = document.querySelector(".show_options");
const navbarBack = document.querySelector("#navbarBack");
const burgerButton = document.querySelector("#burgerButton");
const dropDown = document.querySelector("#navbarNavDropdown");
const navbarToggler = document.querySelector(".navbar-toggler-login");
// const loginPanel = document.querySelector("#navbarSupportedContent");
// const dates = document.querySelector(".dates");
// const datesBoxes = document.querySelectorAll(".dates_box");
// dates && dates.addEventListener("click", (e) => {
//     if (!e.target.closest(".dates_box")) return;
//     datesBoxes.forEach((b) => b.classList.remove("dates_box-selected"));
//     e.target.closest(".dates_box").classList.add("dates_box-selected");
// });

navbarToggler &&
    navbarToggler.addEventListener("click", (e) => {
        if (e.target.closest(".navbar-toggler-login")) {
            const button = e.target.closest(".navbar-toggler-login");
            const panel = button.nextElementSibling;
            // console.log(panel);
            if (panel.style.maxHeight) {
                panel.style.maxHeight = null;
                // console.log("else");
            } else {
                panel.style.maxHeight = `${panel.scrollHeight}px`;
            }
        }
    });
burgerButton &&
    burgerButton.addEventListener("click", () => {
        setTimeout(() => {
            dropDown.style.opacity = "1";
            dropDown.style.visibility = "visible";
        }, 400);

    });

navbarBack &&
    navbarBack.addEventListener("click", () => {
        dropDown.style.opacity = "0";
        dropDown.style.visibility = "hidden";
    });


// SHOW AND HIDE THE CHILD PAGES USING JS
const mobileNavbar = document.querySelector("#main-menu-content");
mobileNavbar.addEventListener("click", (e) => {
    if (e.target.closest(".nav-item-expand")) {
        const expandIcon = e.target.closest(".nav-item-expand");
        expandIcon.classList.toggle("nav-item-expand-on");
        const parent = e.target.closest(".nav-item");
        const dropdown = parent.querySelector(".dropdown-menu-mobile");
        if (dropdown.style.maxHeight) {
            dropdown.style.maxHeight = null;
            dropdown.classList.remove("dropdown-menu-open");
        } else {
            dropdown.classList.add("dropdown-menu-open");
            dropdown.style.maxHeight = `${dropdown.scrollHeight}px`;
        }
    }
});
// USING JS MEDIA QUERY STARTS
// Create a condition that targets viewports more than 992px wide
const navItems = document.querySelectorAll(".nav-item .dropdown-menu");

const mediaQuery = window.matchMedia("(max-width: 991px)");
function handleTabletChange(e) {
    // Check if the media query is true
    if (e.matches) {
        // Then log the following message to the console
        // console.log("Media Query Matched!");
        // loginPanel.classList.add("panel");
        // if (
        //     (dropDown.style.opacity = "0") &&
        //     dropDown.classList.contains("burger_clicked")
        // ) {
        //     dropDown.style.opacity = "1";
        //     dropDown.style.visibility = "visible";
        // }
        // ADDING GENERAL TRIGGER
        // console.log("add dropdown-menu-mobile");
        navItems.forEach((n) => {
            // console.log(n);
            // const menu = n.querySelector(".dropdown-menu");
            n.classList.add("dropdown-menu-mobile");
            if (n.style.maxHeight) n.style.maxHeight = null;
        });
    } else {
        // loginPanel.classList.remove("panel");
        // REMOVING GENERAL TRIGGER
        // console.log("remove dropdown-menu-mobile");
        navItems.forEach((n) => {
            // const menu = n.querySelector(".dropdown-menu");
            n.classList.remove("dropdown-menu-mobile");
            n.classList.remove("dropdown-menu-open");
            if (n.style.maxHeight) n.style.maxHeight = null;
        });
    }
}

// Register event listener
mediaQuery.addListener(handleTabletChange);

// Initial check
handleTabletChange(mediaQuery);
// USING JS MEDIA QUERY ENDS
showOptions &&
    showOptions.addEventListener("click", (e) => {
        if (
            !e.target
                .closest(".show_options")
                .classList.contains("show_options-active")
        ) {
            e.target
                .closest(".show_options")
                .classList.add("show_options-active");
            document.querySelector(".show_options-text").innerText =
                "Hide Options";
        } else {
            e.target
                .closest(".show_options")
                .classList.remove("show_options-active");
            document.querySelector(".show_options-text").innerText =
                "Show more Options";
        }

        showOptionsHidden.forEach((s) => {
            if (!s.classList.contains("show_options-visible"))
                s.classList.add("show_options-visible");
            else s.classList.remove("show_options-visible");
        });
    });

// PUT COLORED BORDER AROUND INPUT TAGS WHEN FOCUSED STARTS
const bookingTextareas = document.querySelectorAll(
    ".booking_form-fields-col textarea"
);

const bookingSelects = document.querySelectorAll(
    ".booking_form-fields-col select"
);

const agreeBoxContainer = document.querySelector(".search_flight-terms-box");
$(".booking_form input").keyup(function (e) {
    // console.log("tab keyup");
    $(this)
        .closest(".booking_form-fields-col")
        .addClass("booking_form-fields-col-focused");
    // OPEN PANEL IN BOOKING FORM WHEN USING TAB TO REACH INPUT TAG OF HIDDEN PANEL
    const parentPanel = this.closest(".general_panel");
    if (parentPanel) {
        if (!parentPanel.classList.contains("general_panel-active")) {
            const trigger = parentPanel.previousElementSibling;
            trigger.click();
        }
    }

    //SHOW CHECKMARK WHEN USER REACHES THE AGREE TERMS CHECKBOX
    if (agreeBoxContainer) {
        // const checkMark = agreeBoxContainer.querySelector(
        //     ".search_flight-filter-input-checkmark"
        // );
        // const checkBox = agreeBoxContainer.querySelector(
        //     ".search_flight-filter-input-checkbox"
        // );
        // if ($(".search_terms-checkbox").is(":focus")) {
        //     console.log(checkMark);
        //     console.log(checkBox);
        //     checkMark.style.opacity = "0.2";
        //     checkMark.style.visibility = "visible";
        //     checkBox.classList.add(
        //         "search_flight-filter-input-checkbox-focused"
        //     );
        // } else {
        //     checkMark.style.opacity = "0";
        //     checkMark.style.visibility = "hidden";
        //     checkBox.classList.remove(
        //         "search_flight-filter-input-checkbox-focused"
        //     );
        // }
    }
});

document.addEventListener("click", (e) => {
    if (e.target.closest(".booking_form-fields-col")) {
        if (
            $(".booking_form-fields-input").is(":focus") ||
            $(".booking_form-fields-col textarea").is(":focus") ||
            $(".booking_form-fields-col select").is(":focus")
        ) {
            e.target
                .closest(".booking_form-fields-col")
                .classList.add("booking_form-fields-col-focused");
        }
    }
});

const focusClassAddRemove = function (e) {
    if (
        e.target
            .closest(".booking_form-fields-col")
            .classList.contains("booking_form-fields-col-focused")
    ) {
        e.target
            .closest(".booking_form-fields-col")
            .classList.remove("booking_form-fields-col-focused");
    }
};

bookingTextareas.forEach((b) =>
    b.addEventListener("focusout", (e) => {
        focusClassAddRemove(e);
    })
);

bookingSelects.forEach((b) =>
    b.addEventListener("focusout", (e) => {
        focusClassAddRemove(e);
    })
);

bookingInputs.forEach((b) =>
    b.addEventListener("focusout", (e) => {
        focusClassAddRemove(e);
    })
);

// PUT COLORED BORDER AROUND INPUT TAGS WHEN FOCUSED STARTS

$("#navbarDropdown").on("click", function (e) {
    // console.log(e.target);
    const dropdown = e.target.nextElementSibling;
    if (dropdown.style.display != "block") dropdown.style.display = "block";
    else dropdown.style.display = "none";
});

// DISABLE THE RETURN DATE AND DEPARTING DATE INPUT FOR INTERNATIONAL AT PAGE LOAD STARTS
$("#returningIntOne").attr("disabled", "disabled");
$("#departingIntRound").attr("disabled", "disabled");
if (oneWayDomesticRadio) oneWayDomesticRadio.checked = true;
if (oneWayVehicleRadio) oneWayVehicleRadio.checked = true;
if (oneWayInternationalRadio) oneWayInternationalRadio.checked = true;
// DISABLE THE RETURN DATE AND DEPARTING DATE INPUT FOR INTERNATIONAL AT PAGE LOAD ENDS

// Disable domestic round trip input at start
$("#departingDomRound").attr("disabled", "disabled").attr("required", false);
// Disable domestic round trip input at start

let tallestTab = 0;

// const tabsContainer = document.querySelectorAll(".general_tabs-container");

// OLD CODE USING VANILLA JS EVENT LISTENER STARTS

// tabsContainer &&
//     tabsContainer.forEach((t) => {
//         t.addEventListener("click", (e) => {
//             if (!e.target.closest(".general_tabs-button")) return;
//             if (!e.target.closest(".search_flight-details-button")) {
//                 if (e.target.closest(".general_tabs-button-active")) return;
//             }
//             if (e.target.closest(".search_flight-details-button")) {
//                 const tabButton = e.target.closest(
//                     ".search_flight-details-button"
//                 );
//                 if (
//                     tabButton.classList.contains("general_tabs-button-active")
//                 ) {
//                     tabButton.classList.remove("general_tabs-button-active");
//                     const targetId = tabButton.dataset.target;
//                     const targetTab = e.currentTarget
//                         .closest(".contains_general-tabs")
//                         .querySelector(`#general_tab-${targetId}`);
//                     targetTab.classList.remove("general_tab-active");
//                     // console.log(targetTab);
//                     // Temporarily disable
//                     // gsap.to(targetTab, {
//                     //     autoAlpha: 0,
//                     //     display: "none",
//                     //     duration: 0.2,
//                     // });
//                     // Temporarily enable
//                     // gsap.to(targetTab, {
//                     //     display: "none",
//                     //     duration: 0.2,
//                     // });
//                     return;
//                 }
//             }

//             const buttons = t.querySelectorAll(".general_tabs-button");

//             // const generalTabs = document.querySelectorAll(".general_tab");
//             const containsTabsContainer = e.currentTarget.closest(
//                 ".contains_general-tabs"
//             );
//             // console.log(containsTabsContainer);
//             buttons.forEach((t) => {
//                 t.classList.remove("general_tabs-button-active");
//             });
//             const generalTabs =
//                 containsTabsContainer.querySelectorAll(".general_tab");
//             generalTabs.forEach((g) => {
//                 g.classList.remove("general_tab-active");
//                 g.querySelector(".booking_check") &&
//                     g
//                         .querySelector(".booking_check")
//                         .classList.remove("booking_check-active");
//                 // Temporarily disable
//                 // gsap.to(g, { autoAlpha: 0, display: "none", duration: 0.2 });
//                 // Temporarily enable
//                 // gsap.to(g, { display: "none", duration: 0.2 });
//             });

//             const clickedButton = e.target.closest(".general_tabs-button");
//             const targetId = clickedButton.dataset.target;
//             const targetTab = containsTabsContainer.querySelector(
//                 `#general_tab-${targetId}`
//             );
//             // To open tab inside baggage details, we have to add general_tab-active class to the
//             // first tab inside targetTab
//             // Check if this targetTab contains other tabs

//             // At first removing general_tab-active class from all tabs and then adding
//             // general_tab-active to the first tab
//             const tabsInsideBaggage =
//                 targetTab.querySelectorAll(".general_tab");
//             tabsInsideBaggage &&
//                 tabsInsideBaggage.forEach((t) => {
//                     t.classList.remove("general_tab-active");
//                 });
//             const tabInsideBaggage = targetTab.querySelector(".general_tab");
//             tabInsideBaggage &&
//                 tabInsideBaggage.classList.add("general_tab-active");

//             // Second removing general_tabs-button-active class from all tab buttons and
//             // then adding active class to the first button tab
//             const tabButtonsInsideBaggage = targetTab.querySelectorAll(
//                 ".general_tabs-button"
//             );
//             tabButtonsInsideBaggage &&
//                 tabButtonsInsideBaggage.forEach((t) => {
//                     t.classList.remove("general_tabs-button-active");
//                 });
//             const tabButtonInsideBaggage = targetTab.querySelector(
//                 ".general_tabs-button"
//             );
//             tabButtonInsideBaggage &&
//                 tabButtonInsideBaggage.classList.add(
//                     "general_tabs-button-active"
//                 );

//             const tabButton = targetTab.querySelector(".general_tabs-button");
//             tabButton && tabButton.classList.add("general_tabs-button-active");
//             clickedButton.classList.add("general_tabs-button-active");
//             targetTab.classList.add("general_tab-active");
//             targetTab.querySelector(".booking_check") &&
//                 targetTab
//                     .querySelector(".booking_check")
//                     .classList.add("booking_check-active");
//             // gsap.fromTo(
//             //     targetTab,
//             //     { autoAlpha: 0, display: "none" },
//             //     {
//             //         autoAlpha: 1,
//             //         display: "block",
//             //         duration: 0.2,
//             //     }
//             // );
//             // Temporarily disable
//             // gsap.fromTo(
//             //     targetTab,
//             //     { autoAlpha: 0 },
//             //     {
//             //         autoAlpha: 1,
//             //         display: "block",
//             //         duration: 0.2,
//             //     }
//             // );
//             // Temporarily enable
//             // gsap.to(targetTab, { display: "block", duration: 0.2 });
//             // if (e.target.closest(".search_flight-results-box")) {
//             //     const currentBox = e.target.closest(
//             //         ".search_flight-results-box"
//             //     );
//             //     console.log("results box", currentBox);
//             //     const containsTabs = currentBox.querySelector(
//             //         ".search_flight-details-box"
//             //     );
//             //     const containsTabsHeight = containsTabs.style.maxHeight;
//             //     const containsTabsHeightNum = containsTabsHeight.split("p")[0];
//             //     console.log("tab max height", containsTabsHeightNum);
//             //     console.log("contains tabs", containsTabs);
//             //     targetTab.style.display = "block";
//             //     const targetTabHeight = targetTab.offsetHeight;
//             //     console.log("target tab height is", targetTab.offsetHeight);
//             //     if (targetTabHeight > containsTabsHeightNum) {
//             //         tallestTab = targetTabHeight;
//             //         containsTabs.style.maxHeight = `${
//             //             targetTabHeight + containsTabsHeightNum
//             //         }px`;
//             //     }
//             // }
//         });
//     });

// OLD CODE USING VANILLA JS EVENT LISTENER ENDS

// OPEN MULTICITY FORM WHEN MULTI CITY RADIO IS CHECKED

// ENABLE THE RETURN FEILD WHEN ROUND TRIP IS CHECKED
const bookingOne = document.querySelector(".booking_form-one");
const bookingMulti = document.querySelector(".booking_form-multi");
const bookingCheckSelected = document.querySelectorAll(
    ".general_tab-active .booking_check"
);

const bookingCheckInternational = document.querySelector(
    ".booking_check-international"
);
const bookingCheckDomestic = document.querySelector(".booking_check-domestic");
const bookingCheckVehicle = document.querySelector(".booking_check-vehicle");

const bookingCheckAddRemove = function (b, bookingCheckOption) {
    const bookingChecks = b.querySelectorAll(".booking_check-option");
    bookingChecks.forEach((b) => {
        b.classList.remove("booking_check-default");
    });
    if (bookingCheckOption) {
        bookingCheckOption.classList.add("booking_check-default");
    }
};

// FUNCTION FOR REMOVING DISABLED ATTRIBUTE AND ADDING REQUIRED ATTRIBUTE
const removeAttrDisabled = function (selector) {
    $(selector).removeAttr("disabled").attr("required", true);
};

// FUNCTION FOR ADDING DISABLED ATTRIBUTE AND REMOVING REQUIRED ATTRIBUTE
const addAttrDisabled = function (selector) {
    $(selector).attr("disabled", "disabled").attr("required", false);
};

// POPULATE ROUND TRIP DATE USING ONE WAY DATE
const populateRoundTripDate = function (selector, litepickerObject) {
    const inputOneValue = document.querySelector(selector).value;
    // console.log(inputOneValue);
    if (litepickerObject) {
        litepickerObject.setOptions({
            startDate: inputOneValue,
        });
        // datePickerRound.setDate(inputOneValue);
        litepickerObject.setDateRange(inputOneValue, inputOneValue);
    }
};

$("#departingVehicleRound").attr("disabled", "disabled");
$("#returningVehicle").attr("disabled", "disabled");

bookingCheckVehicle &&
    bookingCheckVehicle.addEventListener("click", (e) => {
        const parentBookingCheck = e.target.closest(".booking_check");
        bookingCheckAddRemove(
            parentBookingCheck,
            e.target.closest(".booking_check-option")
        );

        if (e.target.closest(".booking_check-vehicle")) {
            const bookingWrapper = e.target.closest(".booking_forms-wrapper");
            const bookingCols = bookingWrapper.querySelectorAll(
                ".booking_form-fields-col"
            );
            if (e.target.closest(".booking_check-option-round")) {
                // console.log("round trip is selected in vehicle");
                // REMOVE DISABLED ATTRIBUTE WHEN ROUND TRIP IS SELECTED
                removeAttrDisabled("#departingVehicleRound");

                addAttrDisabled("#departingVehicle");
                removeAttrDisabled("#returningVehicle");

                // POPULATE ONETRIP DATE TO ROUND TRIP
                populateRoundTripDate(
                    "#departingVehicle",
                    datePickerVehicleRoundReturn
                );
                // SHOW/HIDE DOMESTIC DEPARTING AND RETURNING INPUTS STARTS
                bookingWrapper
                    .querySelector(".booking_depart-one")
                    .classList.remove("booking_depart-one-active");
                bookingWrapper
                    .querySelector(".booking_depart-round")
                    .classList.add("booking_depart-round-active");
                bookingCols.forEach((b) => {
                    if (
                        b.classList.contains("booking_form-fields-col-disabled")
                    ) {
                        b.classList.remove("booking_form-fields-col-disabled");
                    }
                });
            }

            if (e.target.closest(".booking_check-option-one")) {
                // console.log("one way is selected in vehicle");
                // SHOW/HIDE DOMESTIC DEPARTING AND RETURNING INPUTS STARTS
                bookingWrapper
                    .querySelector(".booking_depart-one")
                    .classList.add("booking_depart-one-active");
                bookingWrapper
                    .querySelector(".booking_depart-round")
                    .classList.remove("booking_depart-round-active");
                // DISABLE ROUND TRIP DATE
                addAttrDisabled("#departingVehicleRound");
                removeAttrDisabled("#departingVehicle");
                addAttrDisabled("#returningVehicle");

                bookingCols.forEach((b) => {
                    if (b.classList.contains("booking_form-fields-col-return"))
                        b.classList.add("booking_form-fields-col-disabled");
                });
            }
        }
    });

bookingCheckDomestic &&
    bookingCheckDomestic.addEventListener("click", (e) => {
        const parentBookingCheck = e.target.closest(".booking_check");
        bookingCheckAddRemove(
            parentBookingCheck,
            e.target.closest(".booking_check-option")
        );

        if (e.target.closest(".booking_check-domestic")) {
            const bookingWrapper = e.target.closest(".booking_forms-wrapper");
            const bookingCols = bookingWrapper.querySelectorAll(
                ".booking_form-fields-col"
            );
            if (e.target.closest(".booking_check-option-round")) {
                // REMOVE DISABLED ATTRIBUTE WHEN ROUND TRIP IS SELECTED
                removeAttrDisabled("#returningDom");
                // $("#returningDom")
                //     .removeAttr("disabled")
                //     .attr("required", true);

                // queryselector string, litepicker object arguments
                // POPULATE ONETRIP DATE TO ROUND TRIP
                populateRoundTripDate("#departingDom", datePickerDomRoundReturn);

                // SHOW/HIDE DOMESTIC DEPARTING AND RETURNING INPUTS STARTS
                bookingWrapper
                    .querySelector(".booking_depart-one")
                    .classList.remove("booking_depart-one-active");
                bookingWrapper
                    .querySelector(".booking_depart-round")
                    .classList.add("booking_depart-round-active");
                // SHOW/HIDE DOMESTIC DEPARTING AND RETURNING INPUTS ENDS
                addAttrDisabled("#departingDom");
                removeAttrDisabled("#departingDomRound");
                bookingCols.forEach((b) => {
                    if (
                        b.classList.contains("booking_form-fields-col-disabled")
                    ) {
                        b.classList.remove("booking_form-fields-col-disabled");
                    }
                });
            }
            if (e.target.closest(".booking_check-option-one")) {
                // ADD DISABLED ATTRIBUTE WHEN ROUND TRIP IS SELECTED
                addAttrDisabled("#returningDom");
                const inputRoundValue =
                    document.querySelector("#departingDomRound").value;
                if (datePickerDomOne) {
                    datePickerDomOne.setDate(inputRoundValue);
                }
                bookingWrapper
                    .querySelector(".booking_depart-one")
                    .classList.add("booking_depart-one-active");
                bookingWrapper
                    .querySelector(".booking_depart-round")
                    .classList.remove("booking_depart-round-active");
                removeAttrDisabled("#departingDom");
                addAttrDisabled("#departingDomRound");

                // $("#departingDom")
                //     .removeAttr("disabled")
                //     .attr("required", false);
                bookingCols.forEach((b) => {
                    if (b.classList.contains("booking_form-fields-col-return"))
                        b.classList.add("booking_form-fields-col-disabled");
                });
            }
        }
    });
bookingCheckInternational &&
    bookingCheckInternational.addEventListener("click", (e) => {
        // console.log(e.currentTarget);
        if (e.target.closest(".booking_check-active"))
            if (!e.target.closest(".booking_check-active"))
                // console.log("target");
                return;
        if (!e.target.closest(".booking_check-option")) return;

        const parentBookingCheck = e.target.closest(".booking_check");

        const bookingChecks = parentBookingCheck.querySelectorAll(
            ".booking_check-option"
        );
        bookingChecks.forEach((b) => {
            b.classList.remove("booking_check-default");
        });
        if (e.target.closest(".booking_check-international")) {
            // console.log("this is international");
            const bookingWrapper = e.target.closest(".booking_forms-wrapper");
            const bookingCols = bookingWrapper.querySelectorAll(
                ".booking_form-fields-col"
            );
            if (e.target.closest(".booking_check-option-round")) {
                // REMOVE DISABLED ATTRIBUTE WHEN ROUND TRIP IS SELECTED
                removeAttrDisabled("#returningIntRound");
                populateRoundTripDate("#departingIntOne", datePickerRoundReturn);

                // SHOW/HIDE INTERNATIONAL DEPARTING AND RETURNING WHEN ROUND IS CLICKED STARTS
                // bookingWrapper.querySelector("#departingIntRound").value =
                //     inputOneValue;
                bookingWrapper
                    .querySelector(".booking_depart-one")
                    .classList.remove("booking_depart-one-active");
                bookingWrapper
                    .querySelector(".booking_depart-round")
                    .classList.add("booking_depart-round-active");
                // SHOW/HIDE INTERNATIONAL DEPARTING AND RETURNING WHEN ROUND IS CLICKED ENDS
                addAttrDisabled("#departingIntOne");
                removeAttrDisabled("#departingIntRound");

                // .attr("value", inputOneValue);

                // Removing disabled class for round trip in international form starts

                bookingCols.forEach((b) => {
                    if (
                        b.classList.contains("booking_form-fields-col-disabled")
                    ) {
                        b.classList.remove("booking_form-fields-col-disabled");
                    }
                });
                // Removing disabled class for round trip in international form ends
            }
            if (e.target.closest(".booking_check-option-one")) {
                // ADD DISABLED ATTRIBUTE WHEN ROUND TRIP IS SELECTED
                addAttrDisabled("#returningIntRound");

                const inputRoundValue =
                    document.querySelector("#departingIntRound").value;
                if (datePickerOne) {
                    datePickerOne.setDate(inputRoundValue);
                }

                // console.log("inputRoundValue", inputRoundValue);
                bookingWrapper.querySelector("#departingIntOne").value =
                    inputRoundValue;
                bookingWrapper
                    .querySelector(".booking_depart-one")
                    .classList.add("booking_depart-one-active");
                bookingWrapper
                    .querySelector(".booking_depart-round")
                    .classList.remove("booking_depart-round-active");
                removeAttrDisabled("#departingIntOne");
                addAttrDisabled("#departingIntRound");

                bookingCols.forEach((b) => {
                    // const inputDepart = b.querySelector(".input_depart");
                    // const inputReturn = b.querySelector(".input_return");
                    // if (inputDepart) {
                    //     inputDepart.removeAttribute("id");
                    //     inputDepart.setAttribute("id", "departingIntOne");
                    // }
                    // if (inputReturn) {
                    //     inputReturn.removeAttribute("id");
                    //     inputReturn.setAttribute("id", "returningIntOne");
                    // }
                    // inputDepart.setAttribute("id", "departingIntOne");

                    // inputReturn &&
                    //     inputReturn.setAttribute("id", "returningIntOne");

                    if (b.classList.contains("booking_form-fields-col-return"))
                        b.classList.add("booking_form-fields-col-disabled");
                });
            }
        }

        if (e.target.closest(".booking_check-domestic")) {
            // console.log("this is domestic");
        }

        if (e.target.closest(".booking_check-option")) {
            const bookingCheck = e.target.closest(".booking_check-option");
            bookingCheck.classList.add("booking_check-default");
        }
        if (!e.target.closest(".booking_check-option-multi")) {
            bookingOne.style.display = "block";
            bookingMulti.style.display = "none";
        }

        if (e.target.closest(".booking_check-option-multi")) {
            // console.log("multi tab is clicked");
            bookingOne.style.display = "none";
            bookingMulti.style.display = "block";
            multiFlight1.checked = true;
        }

        // if (radioRound.checked) {
        //     console.log("checked");
        // }
    });

// console.log(bookingCheckSelected);

const multiFlight1 = document.querySelector("#multi_flight1");
// console.log(bookingCheckSelected);
bookingCheckSelected &&
    bookingCheckSelected.forEach((b) => {
        b.addEventListener("click", (e) => {});
    });

// bookingCheckSelected.addEventListener("click", (e) => {
//     if (e.target.closest(".booking_check-option-round"))
//         console.log("target reached");
//     // if (radioRound.checked) {
//     //     console.log("checked");
//     // }
// });

const brand = document.querySelector(".general_tabs-button-active");
brand && brand.addEventListener("click", (c) => console.log(this));

const multiFlight = document.querySelectorAll("multi_flight");
const multiWrapper = document.querySelector(".multi_wrapper");
const multiFlightRows = document.querySelectorAll(".multi_flight-row");

let flightNum = 2;

const addFlight = function (element, number) {
    const addFlight = `<span class="multi_flight">

    <symbol class="svg_med id="icon-airplanemode_on" viewBox="0 0 24 24">
    <path d="M21 15.984l-8.016-2.484v5.484l2.016 1.5v1.5l-3.516-0.984-3.469 0.984v-1.5l1.969-1.5v-5.484l-7.969 2.484v-1.969l7.969-5.016v-5.484q0-0.609 0.445-1.055t1.055-0.445 1.055 0.445 0.445 1.055v5.484l8.016 5.016v1.969z"></path>
    </symbol>
        <input type="radio" name="flight_option" id="multi_flight${number}>
        <label for="multi_flight${number}">Flight ${number}</label>
    </span>`;
    element.insertAdjacentHTML("beforebegin", addFlight);
    document.querySelector(`#multi_flight${number} `).checked = true;
};

multiWrapper &&
    multiWrapper.addEventListener("click", (e) => {
        if (e.target.closest(".multi_flight-add")) {
            flightNum += 1;
            addFlight(e.target, flightNum);
        }
        if (e.target.closest(".multi_flight")) {
            multiFlight.forEach((m) =>
                m.classList.remove("multi_flight-active")
            );
            multiFlightRows.forEach((m) =>
                m.classList.remove("multi_flight-row-active")
            );

            const targetId = e.target.dataset.target;
            const target = document.querySelector(`#multi-${targetId}`);
            target.classList.add("multi_flight-row-active");
        }
    });

// Flight details tab js
const flightDetailButton = document.querySelectorAll(".search_flight-details");
const flightTr = document.querySelectorAll(".flightSearch_tr");
const flightPanel = document.querySelectorAll(".search_flight-details-box");
const searchFlightResults = document.querySelector(".search_flight-results");
// flightDetailButton.forEach((b) => {
//     b.addEventListener("click", (e) => {
//         console.log(e.target.closest(".flightSearch_tr"));
//     });
// });

// FLIGHT INFORMATION ACCORDION
// if (searchFlightResults != null) {
//     searchFlightResults.addEventListener("click", (e) => {
//         if (!e.target.classList.contains("search_flight-details")) return;
//         // Remove active classes from flightDetail and flightPanels
//         let tabMaxHeight = 0;
//         const detailButton = e.target;
//         const resultsBox = detailButton.closest(".search_flight-results-box");
//         const allGeneralTabs = resultsBox.querySelectorAll(".general_tab");
//         // allGeneralTabs.forEach((t) => console.log(t.scrollHeight));
//         console.log(resultsBox);
//         // console.log(detailButton);
//         flightDetailButton.forEach((b) =>
//             b.classList.remove("flightSearch_detail-active")
//         );
//         flightPanel.forEach((f) =>
//             f.classList.remove("flightSearch_panel-active")
//         );

//         // Select the flight panel
//         const parentTr = detailButton.closest(".search_flight-results-box");
//         const panel = parentTr.querySelector(".search_flight-details-box");

//         // const panel = siblingTr.querySelector(".flightSearch_panel");

//         // Apply active class to selected panel and button
//         detailButton.classList.add("flightSearch_detail-active");
//         panel.classList.add("flightSearch_panel-active");
//         if (panel.style.maxHeight) {
//             panel.style.maxHeight = null;
//             detailButton.classList.remove("flightSearch_detail-active");
//             panel.classList.remove("flightSearch_panel-active");
//         } else {
//             panel.style.maxHeight = `${panel.scrollHeight + 16}px`;
//         }
//     });
// }

// const departPicker = new Litepicker({
//     element: document.getElementById("departingIntOne"),
//     elementEnd: document.getElementById("returningIntOne"),
//     singleMode: false,
//     allowRepick: true,
// });

document.addEventListener("click", (e) => {
    // console.log("general-trigger-target", e.target);
    if (e.target.closest(".general_trigger")) {
        // console.log("general trigger found");
        const allTriggers = document.querySelectorAll(".general_trigger");
        const allPanels = document.querySelectorAll(".general_panel");
        // allPanels.forEach((p) => p.classList.remove(".general_panel-active"));
        // allTriggers.forEach((t) =>
        //     t.classList.remove("general_trigger-active")
        // );

        const selectedTrigger = e.target.closest(".general_trigger");
        const selectedPanel = selectedTrigger.nextElementSibling;
        selectedTrigger.classList.add("general_trigger-active");
        selectedPanel.classList.add("general_panel-active");
        if (selectedPanel.style.maxHeight) {
            selectedPanel.style.maxHeight = null;
            selectedTrigger.classList.remove("general_trigger-active");
            selectedPanel.classList.remove("general_panel-active");
        } else {
            selectedPanel.classList.add("general_panel-active");
            selectedPanel.style.maxHeight = `${
                selectedPanel.scrollHeight + 20
            }px`;
        }
    }
    // console.log("general trigger");
});

document.addEventListener("click", (e) => {
    if (!e.target.closest(".booking_search-button")) return;
});

const searchButton = document.querySelector(".booking_search-button");
searchButton &&
    searchButton.addEventListener("click", (e) => {
        if (e.target.classList.contains("booking_search-button")) {
            e.target.querySelector(".button_submit").click();
        }
    });

// USING GREENSOCK TO ANIMATE THE LOADER STARTS

// Function that gets called when booking form loader is submitted

// GSAP TIMELINE
// gsap.to(".navbar-brand", { x: 100, duration: 1 });
// gsap.to(".navbar-brand", { y: 50, duration: 1, delay: 1 });
// gsap.to(".navbar-brand", { opacity: 0, duration: 1, delay: 2 });

// const tl = gsap.timeline({ repeat: 3, repeatDelay: 1, yoyo: true });
// tl.to(".navbar-brand", { x: 100, duration: 1 });
// tl.to(".navbar-brand", { x: 0, duration: 1 });
// tl.to(".navbar-brand", { opacity: 0, duration: 1 });

const loaderStartFunction = function () {
    const loaderLine1 = document.querySelector(".loader_line-11");
    const loaderLine2 = document.querySelector(".loader_line-22");
    const loaderLine3 = document.querySelector(".loader_line-33");
    const loaderLine4 = document.querySelector(".loader_line-44");
    const loaderLine5 = document.querySelector(".loader_line-55");
    const loaderLine6 = document.querySelector(".loader_line-66");
    const loaderSvg = document.querySelector(".loader_svg");
    // const loaderMsg = document.querySelector(".loader_msg");
    const lineOneLength = loaderLine1.getTotalLength();
    const lineTwoLength = loaderLine2.getTotalLength();
    const lineThreeLength = loaderLine3.getTotalLength();
    const loaderTimeline = new gsap.timeline({ repeat: -1, yoyo: false });
    let start = 350;
    let end = 200;

    loaderTimeline.fromTo(
        loaderSvg,
        {
            delay: 5,
            duration: 0.4,
            autoAlpha: 0,
            y: start,
        },
        {
            autoAlpha: 1,
            y: end,
        }
    );
    loaderTimeline.to(
        loaderLine1,
        {
            duration: 1,
            strokeDashoffset: 2 * lineOneLength,
            autoAlpha: 1,
        },
        "<"
    );
    loaderTimeline.to(loaderSvg, { duration: 0.4, y: `${end - 5}` }, "<");
    loaderTimeline.to(
        loaderLine6,
        {
            duration: 1,
            strokeDashoffset: 2 * lineOneLength,
            autoAlpha: 1,
        },
        "<"
    );
    loaderTimeline.to(loaderSvg, { duration: 0.4, y: `${end - 10}` }, "<");
    loaderTimeline.to(
        loaderLine2,
        {
            duration: 1.1,
            strokeDashoffset: 2 * lineTwoLength,
            autoAlpha: 1,
        },
        "-=0.75"
    );
    loaderTimeline.to(loaderSvg, { duration: 0.4, y: `${end - 15}` }, "<");
    loaderTimeline.to(
        loaderLine5,
        {
            duration: 1.1,
            strokeDashoffset: 2 * lineTwoLength,
            autoAlpha: 1,
        },
        "-=0.75"
    );
    loaderTimeline.to(loaderSvg, { duration: 0.4, y: `${end - 20}` }, "<");
    loaderTimeline.to(
        loaderLine3,
        {
            duration: 1.1,
            strokeDashoffset: 2 * lineThreeLength,
            autoAlpha: 1,
        },
        "-=0.75"
    );
    loaderTimeline.to(loaderSvg, { duration: 0.4, y: `${end - 25}` }, "<");
    loaderTimeline.to(
        loaderLine4,
        {
            duration: 1.1,
            strokeDashoffset: 2 * lineThreeLength,
            autoAlpha: 1,
        },
        "-=0.75"
    );
    loaderTimeline.to(loaderSvg, { duration: 0.4, y: `${end - 30}` }, "<");
    // loaderTimeline.to(loaderSvg, { duration: 0.4, y: -50 }, "<");

    loaderTimeline.fromTo(
        loaderSvg,
        {
            duration: 0.5,
            autoAlpha: 1,
            y: `${end - 35}`,
        },
        {
            autoAlpha: 0,
            y: `${end - 40}`,
        },
        "<"
    );
};

// Show the preloader for adding message
// const loader = document.querySelector(".loader");
// loader && loader.classList.add("loader-active");
// document.querySelector("body").classList.add("overflow_hidden");
// loaderStartFunction();

// Show the preloader when submitting

$(".booking_form-loader").on("submit", () => {
    const loader = document.querySelector(".loader");
    loader && loader.classList.add("loader-active");
    document.querySelector("body").classList.add("overflow_hidden");
    loaderStartFunction();
});

// second part

// setInterval(() => {

// }, 2000);

// gsap.to(loaderLine1, {
//     duration: 2,
//     strokeDashoffset: 2 * lineOneLength,
//     delay: 1,
// });

// USING GREENSOCK TO ANIMATE THE LOADER ENDS

// searchFlightResults.addEventListener("mouseover", (e) => {
//     if (e.target.closest(".search_flight-results-box")) {
//         const box = e.target.closest(".search_flight-results-box");
//         const tabs = box.querySelector(".search_flight-details");
//         // tabs.style.display = "none";
//         tabs.style.transform = "translateY(0px)";
//     }
// });

// searchFlightResults.addEventListener("mouseout", (e) => {
//     if (e.target.closest(".search_flight-results-box")) {
//         const box = e.target.closest(".search_flight-results-box");
//         const tabs = box.querySelector(".search_flight-details");
//         // if (tabs.style.transform === "translateY(0px)")
//         const buttons = tabs.querySelectorAll(".general_tabs-button");
//         let i = 0;
//         buttons.forEach((b) => {
//             console.log(i);
//             i++;
//             if (!b.classList.contains("general_tabs-button-active"))
//                 tabs.style.transform = "translateY(100%)";
//             // break;
//         });
//     }
// });

$(document).ready(function () {
    $("#home_carousel").owlCarousel({
        loop: true,
        margin: 30,
        nav: true,
        autoplay: true,
        smartSpeed: 700,
        autoplayTimeout: 5000,
        autoplayHoverPause: false,
        dots: true,
        responsive: {
            0: {
                items: 1,
            },
            600: {
                items: 1,
            },
            1000: {
                items: 1,
            },
        },
    });
});

// $(document).ready(function(){
//     $('.down-arrow').click(function(){
//         // $('.dropdown-menu').toggleClass('.dropdown-menu-small')
//         $('.dropdown-menu').toggleClass('.dropdown-menu-full')

//     });
// });



$(document).ready(function(){
    $('.flight_detail-button1').click(function(){
        // $(this).parents('.search_flight-results-box').children('.general_tab-main').toggleClass('general_tab_visible');
        $(this).parents('.search_flight-results-box').children('.flight-detail-content').slideToggle(500);

    });
});

$(document).ready(function(){
    $('.search_flight-summ-details-modify').click(function(){
        $('.modify_form').toggleClass('overflow-in');
        console.log('12he');
        $('.modify_form').css('max-height','auto !important');

    });
});


