// import flatpickr from "flatpickr";
// require("flatpickr/dist/themes/material_blue.css");
/**
 * Place any jQuery/helper plugins in here.
 */
$(function() {
    /**
     * Assign livewire values to input fields
     *
     * @param option
     */
    $(document).on("click", ".npOption", function(e) {
        // console.log($(this).data('disp'));
        var optWrap = $(this).closest(".npOptionWrap");
        optWrap.find(".npOptionInput").val($(this).data("rel"));
        optWrap.find(".npOptionText").addClass('withValue').attr('placeholder',$(this).data("disp")).val('');
        optWrap.find(".npRemovable").remove();
    });

    $(document).on("click", ".asOption", function(e) {
        // console.log($(this).data('disp'));
        var optWrap = $(this).closest(".npOptionWrap");
        optWrap.find(".npOptionInput").val($(this).data("rel"));
        optWrap.find(".npOptionText").addClass('withValue').val($(this).data("disp"));
        optWrap.find(".npRemovable").remove();
    });

    // flatpickr(".npDatePicker", {
    //     allowInput: true,
    //     wrap: true
    // });
});
