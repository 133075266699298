/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require("../bootstrap");
require("../plugins");
require("../apps");
require("../app-menu");
// require('../jquery');
require("../components-modal");
require("../popover");
require("../sscustom");
require("../npcustom");

import $ from "jquery"
import "owl.carousel"
import Litepicker from 'litepicker';

import Alpine from "alpinejs";
window.Alpine = Alpine;

Alpine.start();
//lightbox-2
import "lightbox2/dist/js/lightbox.min.js";




// Comment vue error
// import Vue from "vue";

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

// Comment vue error
// Vue.component(
//     "example-component",
//     require("./components/ExampleComponent.vue").default
// );

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

// Comment vue error
// const app = new Vue({
//     el: "#app"
// });


$('.dropdown_front').hover(function(){
    $('.dropdown-toggle_front', this).trigger('click');
  });
